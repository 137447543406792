<template>
  <Modal v-if="modal" class="Financial__Download__Modal" @close="close">
    <template #header>
      <div class="Financial__Download__Modal__Header">
        <span>Planilhas para download</span>
      </div>
    </template>

    <template #body>
      <div class="Financial__Download__Modal__Body">
        <p>{{ consolidateId ? 'Selecione o tipo de planilha que deseja fazer download:' : 'Veja abaixo os padrões de planilhas a serem utilizados:' }}</p>

        <DataTable
          :header="table.headers"
          :table="table.content"
          :max-size="false"
          class="Table"
          @download="download"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import { DataTable, Modal } from '@/components/molecules'
import { financial } from '@/api'

export default {
  name: 'DownloadSpreadsheetModal',
  components: {
    DataTable,
    Modal,
  },

  props: {
    page: {
      type: String,
      default: 'uploadSheets',
      validator: (val) => ['uploadSheets', 'cnab', 'fortnight'].includes(val),
    }
  },

  data() {
    return {
      modal: false,
      table: { content: [], headers: {} },
      consolidateId: 0
    }
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      setError: 'error/setError'
    }),

    //Handlers
    open(table, id = 0) {
      this.modal = true
      this.$emit('open')
      this.table = table
      this.consolidateId = id
    },

    close() {
      this.modal = false
      this.$emit('close')
    },

    async download(data) {
      this.setLoading(true)

      if(this.page === 'fortnight') {
        this.financialConsolidateDownloadOptions(data)
      } else if(this.page === 'uploadSheets') {
        await financial.downloadSpreadsheetModel(data.id, (r) => r, (e) => e, () => this.setLoading(false))
      } else {
        const params = { id: this.consolidateId, type: data?.type?.toLowerCase() }
        const endpoint = this.page === 'cnab' ? 'downloadPaymentClosingFile' : 'downloadConsolidateCNAB'
        await financial[endpoint](params, (r) => r, (e) => e, () => this.setLoading(false))
      }
    },

    async financialConsolidateDownloadOptions(data) {
      const endpoint = {
        0: 'downloadConsolidate',
        1: 'downloadPreFinancialConsolidate',
        2: 'downloadDailyPreConsolidate',
      }
      await financial[endpoint[data.id]](this.consolidateId, (r) => {
        this.getModel(r.result.id, data)
      }, (e) => e, () => this.setLoading(false))
    },

    async getModel(id, data) {
      this.setLineLoading(data, true)

      await financial.downloadFinancialReports(id, (res) => {
        if (res) {
          setTimeout(() => {
            this.getModel(id, data)
          }, 5000)
        } else {
          this.setLineLoading(data, false)
        }
      }, () => {
        this.setLineLoading(data, false)
      })
    },

    setLineLoading(data, setLoading) {
      const report = this.table.content.find(el => el.id === data.id)

      report.actions = [{action_id: 'loading', has_permission: true}]
      
      if(report && !setLoading) {
        report.actions = [{ action_id: 'download', has_permission: true }]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.Financial__Download {
  &__Modal {

    /deep/ .Modal__container {
      width: 450px;
      height: auto;

      @media #{$mobile-view} {
        width: 100%;
      }
    }

    &__Header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        font-weight: $font-weight-bold;
      }
    }

    &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;

      > p {
        text-align: center;
        padding: 0 25px;
      }

      .Table {
        width: 100%;
        margin-top: 10px;
        width: 100%;
      }
    }

  }
}
</style>
